export default [
    {
        name: "Gesund-20%",
        value: 1
    },
    {
        name: "Leicht 20-30%",
        value: 2
    },
    {
        name: "Geschädigt 30-50%",
        value: 3
    },
    {
        name: "Stark Geschädigt 50-90%",
        value: 4
    },
    {
        name: "Absterbend 100%",
        value: 5
    },
    
]