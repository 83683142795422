export default [
    {
        name: "S",
        value: 1
    },
    {
        name: "SW",
        value: 2
    },
    {
        name: "W",
        value: 3
    },
    {
        name: "NW",
        value: 4
    },
    {
        name: "N",
        value: 5
    },
    {
        name: "NO",
        value: 6
    },
    {
        name: "O",
        value: 7
    },
    {
        name: "SO",
        value: 8
    },
    
   
   
   
    
]