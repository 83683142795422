export default{
    RootSpace_RootDamage: 1,
    TrunkBase_Necrosis: 2,
    TrunkBase_Wound: 3,
    TrunkBase_Crack: 4,
    Trunk_Necrosis: 5,
    Trunk_Wound:6,
    Trunk_Crack: 7,
    CrownBase_Necrosis: 8,
    CrownBase_Wound: 9,
    CrownBase_Crack: 10,
    Crown_Necrosis: 11,
    Crown_Wound: 12,
    Crown_Crack: 13
}